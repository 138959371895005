import { restApi } from "@/api/http";
const requestUrl = "https://app.lawtrust.cn";
// window.location.origin === "https://heguixueyuan.com" ||
// window.location.origin === "https://ilaw.law" ||
// window.location.origin === "https://www.ilaw.law"
//   ? "https://app.lawtrust.cn"
//   : "https://testapp.lawtrust.cn";
export default {
  getNewsList(data) {
    return restApi({
      url: "/backend/api/admin/v1/news/list",
      method: "post",
      data: data,
    });
  },
  summaryHome() {
    return restApi({
      url: "/backend/api/admin/v1/summary/home",
      method: "get",
      params: {},
    });
  },
  verifyCode(params) {
    return restApi({
      url: "/backend/api/student/v1/login/login/verifyCode",
      method: "get",
      params: params,
    });
  },
  register(data) {
    return restApi({
      url: "/backend/api/student/v1/login/register",
      method: "post",
      data: data,
    });
  },
  login(data) {
    return restApi({
      url: "/backend/api/student/v1/login/login",
      method: "post",
      data: data,
    });
  },
  districts(params) {
    return restApi({
      url: "/backend/api/system/districts",
      method: "get",
      params: params,
    });
  },
  // 预约
  addClues(data) {
    return restApi({
      url: `${requestUrl}/lawtrust/api/v1/official/addClues`,
      method: "post",
      data: data,
    });
  },
  // 查询公众号文章
  getArticles(params) {
    return restApi({
      url: `${requestUrl}/lawtrust/api/v1/official/articles/page`,
      method: "get",
      params: params,
    });
  },
  // 查询公众号文章副刊
  getSupplement(params) {
    return restApi({
      url: `${requestUrl}/lawtrust/api/v1/official/articles/live/page`,
      method: "get",
      params: params,
    });
  },
  // 获取视频url
  getVideoUrl(params) {
    return restApi({
      url: `${requestUrl}/lawtrust/api/v1/official/getSignatureUrl`,
      method: "post",
      params: params,
    });
  },
  // 获取精选课程
  getSelected(data) {
    return restApi({
      url: `${requestUrl}/lawtrust/api/v1/official/courseLists`,
      method: "post",
      data: data,
    });
  },
};
