import axios from 'axios'
import { Message } from 'element-ui/lib'
import qs from 'qs'

export const restApi = ({ url, method = 'get', data = null, params = null, headType = null, uploadSts = null }) => {

  let headers = { }
  if (headType) {
    const _type = headType.toLocaleLowerCase()
    switch (_type) {
      case 'formdata':
        headers = {
          'Content-Type': 'multipart/form-data'
        }
        data = formData(data)
        break
      case 'form':
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        data = `request=${data.request}`
        break
      case 'upload':
        headers = {
          'Content-Type': 'image/jpg',
          'x-oss-meta-author': 'longfengshan'
        }
        break
      case 'mp4':
        headers = {
          'Content-Type': 'audio/mp4',
          'x-oss-meta-author': 'longfengshan'
        }
        break
      case 'uploadsts':
        headers = {
          tempToken: uploadSts
        }
        break
      default: break
    }
  }
  return axios({
    headers,
    method,
    url,
    data,
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  }).then((response) => {
    return response.data
  }).catch(async e => {
    if (e.response) {
      const status = e.response.status
      switch (status) {
        case 401:
          console.dir(e)
          // if (!store.state.user.canResh) {
          //   store.commit('user/SET_ISRESH', true)
          //   store.dispatch('user/refreshToken', {
          //     refreshToken: localStorage.refreshToken
          //   })
          // }
          // router.push('/login')
          break
        case 400:
          if (e.response.data.resultMsg) {
            Message.error(e.response.data.resultMsg)
          } else {
            Message.error(JSON.stringify(e.response))
          }
          console.dir(e)
          break
        case 403:
          console.dir(e)
          Message.error(JSON.stringify(e.response))
          break
        case 404:
          console.dir(e)
          Message.error('接口404了，问后端咋回事')
          break
        default:
          if (e.response && e.response.data && e.response.data.resultMsg) {
            return Message.error(e.response.data.resultMsg.toString())
          }
          console.dir(e)
          Message.error(e.toString())
      }
    } else {
      console.dir(e)
      Message.error(e.toString())
    }
    return null
  })
}
const formData = (arg) => {
  var _obj = new FormData()
  for (const x in arg) {
    if (arg[x] instanceof Array) {
      arg[x].forEach(item => {
        return _obj.append(x, item)
      })
    } else {
      _obj.append(x, arg[x])
    }
  }
  return _obj
}
export default restApi
